
@use '@/assets/styles/_variables.scss';

@import 'variables';

/* recommended css code for vue-svgicon */
.svg-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  color: inherit;
  vertical-align: middle;
  fill: none;
  stroke: currentColor;
}

.svg-fill {
  fill: currentColor;
  stroke: none;
}

.svg-up {
  /* default */
  transform: rotate(0deg);
}

.svg-right {
  transform: rotate(90deg);
}

.svg-down {
  transform: rotate(180deg);
}

.svg-left {
  transform: rotate(-90deg);
}
.v-list-item__content{
  text-align: left !important;
}

// needed to override bad styles from reverse proxy
html > body {
  overflow: hidden;
}

:root {
  --indigo-darken-4: #{$indigo-darken-4};
  --red-darken-1: #{$red-darken-1};
  --blue-darken-4: #{$blue-darken-4};
  --blue: #{$blue};
  --red-darken-4: #{$red-darken-4};
  --deep-orange-accent-3: #{$deep-orange-accent-3};
  --teal-darken-3: #{$teal-darken-3};
  --teal-darken-1: #{$teal-darken-1};
  --purple-darken-4: #{$purple-darken-4};
  --deep-purple-lighten-2: #{$deep-purple-lighten-2};

  --black: #{$black};
  --grey-darken-3: #{$grey-darken-3};
  --grey-darken-1: #{$grey-darken-1};
  --grey-lighten-1: #{$grey-lighten-1};
  --grey-lighten-2: #{$grey-lighten-2};
  --grey-lighten-3: #{$grey-lighten-3};
  --grey-lighten-4: #{$grey-lighten-4};
  --grey-lighten-5: #{$grey-lighten-5};
  --grey-blue-1: #{$grey-blue-1};
  --white: #{$white};

  --primary: var(--indigo-darken-4);
  --primary-base: 17,49,128;
  --secondary: var(--red-darken-1);
  --accent: var(--blue-darken-4);
  --error: var(--deep-orange-accent-3);
  --warning: #E69709;
  --info: var(--blue);
  --success: #67BB37;

  --gradient-primary: linear-gradient(135deg, var(--blue-darken-4) 0%, var(--blue-darken-4) 15%, var(--primary) 100%);
  --gradient-secondary: linear-gradient(135deg, var(--secondary) 0%, var(--secondary) 15%, var(--red-darken-4) 100%);

  --gradientBlue: linear-gradient(135deg, var(--blue-darken-4) 0%, var(--blue-darken-4) 15.5%, var(--indigo-darken-4) 100%);
  --gradientRed: linear-gradient(135deg, var(--red-darken-1) 0%, var(--red-darken-1) 15.5%, var(--red-darken-4) 100%);

  /* todo remove */
  --red: red;

  /* custom colors */
  //--deep-red: var(--deep-red);
  //--deep-red-bg: var(--deep-red-bg);
  --deep-red: #{$deep-red};
  --deep-red-bg: #{$deep-red-bg};
}
